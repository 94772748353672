import Api from '@/axios'

export default {
  getProductDetails (id) {
    return Api().get(`pages/categories/item/${id}`)
  },
  addToCart (id, payload) {
    return Api().post(`users/carts/${id}`, payload)
  },
  buyNow (id, payload) {
    return Api().post(`users/orders/buy-now/${id}`, payload)
  }
}
